import React from "react";
import Doctors from "./components/Doctors";
// import Emergency from "./components/Emergency";
import FewWords from "./components/FewWords";
import Footer from "./components/Footer";
// import Testimonial from "./components/Testimonial";
import About from "./containers/About";
import ContactUs from "./containers/ContactUs";
import Home from "./containers/Home";
import Services from "./containers/Services";

import {useState, useEffect} from 'react';

import './index.css'

function App() {


  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);


  return (
    <div onScroll={handleScroll} className="m-0 p-0 box-border portrait:text-xl">
      <Home offSetValue={scrollPosition} />
      <Services />
      {/* <Emergency /> */}
      <About />
      <Doctors />
      {/* <Testimonial /> */}
      <FewWords />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
