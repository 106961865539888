import React from 'react'
// import { MdMedication } from 'react-icons/md';

const Services = () => {
    return (
        <div className='flex flex-col duration-500 transition-all portrait:w-full' id='service'>
            <div className='w-full h-24'></div>
            <div className='flex flex-col lg:flex-row justify-center items-start  w-11/12 m-auto'>
                <div className='flex flex-col basis-2/4 md:mr-12 sm:pb-12'>
                    <h1 className='text-3xl text-black font-bold uppercase pb-4'>Our Services</h1>
                    <div className='w-12 h-1' style={{ backgroundColor: "#008E9B" }}></div>

                    <div className='flex flex-col py-2'>
                        {/* <MdMedication style={{ color: "#008E9B" }} size={40} /> */}
                        <h3 className='text-xl py-3 font-bold'>General Services</h3>
                        <p className='pb-6'>Sub-section include: In-Patient & Out-Patient Care,
                            General Medical Care, General Surgical Care, Home Medical Care,
                            Endoscopy / Colonoscopy, Wound Management, Electrocardiogram,
                            Ultrasound Scan, Laboratory Services, Pharmacy
                        </p>
                    </div>
                    <div className='flex flex-col py-2'>
                        {/* <MdMedication style={{ color: "#008E9B" }} size={40} /> */}
                        <h3 className='text-xl py-3 text-green-600 font-bold'>Specialist care</h3>
                        <p className='pb-6'>Oncology, Neurological Care, Urological Care,
                            Diabetes, Hypertension, & Cardiac Clinics, Obstetrics / Gynaecology,
                            Maternal Care, Family Planning / Education, Plastic Surgery
                        </p>
                    </div>
                </div>
                <div className='flex flex-col basis-3/4 md:mr-12 sm:pb-12'>
                    <div>
                        <h1 className='text-3xl text-black font-bold uppercase pb-4'>Our Partners</h1>
                        <div className='w-12 h-1' style={{ backgroundColor: "#008E9B" }}></div>
                    </div>
                    <div className='grid lg:grid-cols-2 w-full lg:w-full sm:grid-cols-1'>
                        <div className='flex flex-col py-2 pr-2'>
                            <h3 className='text-xl py-3 font-bold'>Partners</h3>
                            <p className='pb-6'>Asokore Mampong Traditional Council, Ghana Health Service, Ministry of
                                Health, Asokore Mampong Municipal Assembly, HeFRA, NHIA.
                            </p>
                        </div>
                        <div className='flex flex-col py-2 pr-2'>
                            <h3 className='text-xl py-3 font-bold'>Affiliated Bodies</h3>
                            <p className='pb-6'>Legacy Innovation, Legacy Support Foundation,
                            </p>
                        </div>
                        <div className='flex flex-col py-2'>
                            <h3 className='text-xl py-3 font-bold'>24 Hour Support</h3>
                            <p className='pb-6'>Visiting Hours:
                                Morning: 6:00  - 6:30 GMT  | Evening: 5:00 - 6:00 GMT
                            </p>
                            <p className='pb-6 text-red-700 '>NB:
                                <span className='text-black pl-2'>We accept NHIS</span>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services