import React, { useRef } from 'react'
import { GoLocation } from 'react-icons/go';
import { BiCurrentLocation, BiEnvelope } from "react-icons/bi";
import { BsTelephoneFill } from 'react-icons/bs';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactUs = () => {
    const formRef = useRef();

    const notify = () => {
        toast.success('🦄 Message sent successully', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    // service_nkjmizs

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9eirhvp',
            'template_aq4c208',
            formRef.current,
            'QW-iwDRp2US-f1von')
            .then((result) => {
                notify();
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    };

    // const sendEmail = (e) => {
    //     e.preventDefault();

    //     emailjs.sendForm('service_ul5m1ve',
    //         'template_mjaxtb8',
    //         formRef.current,
    //         'xcn8PQhOF1roSgL6E')
    //         .then((result) => {
    //             notify();
    //         }, (error) => {
    //             console.log(error.text);
    //         });
    //     e.target.reset();
    // };

    return (
        <div className='flex flex-col duration-500 transition-all' id='contact'>
            <div className='w-full h-24'></div>
            <div className='flex flex-col justify-center m-auto w-11/12 pb-10 items-start'>
                <div className='flex flex-col basis-1/4 mr-28'>
                    <h1 className='text-3xl text-black font-bold uppercase pb-4'>Contact Us</h1>
                    <div className='w-12 h-1' style={{ backgroundColor: "#008E9B" }}></div>
                </div>
                <div className='flex flex-col lg:flex-row w-11/12 mt-8'>

                    <div className='flex flex-col items-start pb-20 mr-24'>
                        <h2 className='font-bold text-2xl capitalize'>Contact Info</h2>
                        <div className='flex flex-col mt-8 text-xl'>
                            <div className='flex flex-row pb-5 items-start'>
                                <i className='mr-5'><GoLocation size={40} style={{ color: '#008E9B' }} /></i>
                                <div>
                                    <p>Asokore Mampong, </p>
                                    <p>Boakye Ansah Debrah street, Kumasi</p>
                                </div>
                            </div>
                            <div className='flex flex-row pb-5 items-start'>
                                <i className='mr-5'><BiCurrentLocation size={40} style={{ color: '#008E9B' }} /></i>
                                <div>
                                    <p>GPS code, </p>
                                    <p>AS 010-0946</p>
                                </div>
                            </div>
                            <div className='flex flex-row pb-5 items-start'>
                                <i className='mr-5'><BiEnvelope size={40} style={{ color: '#008E9B' }} /></i>
                                <div>
                                    <p>info@legacyhospital.org</p>
                                </div>
                            </div>
                            <div className='flex flex-row pb-5 items-start'>
                                <i className='mr-5'><BsTelephoneFill size={40} style={{ color: '#008E9B' }} /></i>
                                <div>
                                    <p>+233 201 2936 81</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* AS/010-0946 */}

                    <div className='flex flex-col w-full items-start pb-20'>
                        <h2 className='font-bold text-2xl capitalize'>Having Any Query! Or Book an appointment</h2>
                        <div className='flex flex-col mt-8 text-xl w-full'>
                            <div className='flex flex-row pb-5 items-start'>
                                <form ref={formRef} onSubmit={sendEmail} className='w-full'>
                                    <div className='block w-full text-2xl mb-5'>
                                        <input className='mt-1 block w-full px-3 py-1 bg-white border border-slate-300 rounded-md text-lg placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                     invalid:text-pink-600
                                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            type='text' placeholder='Name' name='name' required />
                                    </div>
                                    <div className='block w-full text-2xl mb-5'>
                                        <input className='mt-1 block w-full px-3 py-1 bg-white border border-slate-300 rounded-md text-lg placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                     invalid:text-pink-600
                                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            type='email' placeholder='Your Email' name='user_email' required />
                                    </div>
                                    <div className='block w-full text-2xl mb-5'>
                                        <input className='mt-1 block w-full px-3 py-1 bg-white border border-slate-300 rounded-md text-lg placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                     invalid:text-pink-600
                                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            type='text' placeholder='Subject' name='subject' required />
                                    </div>
                                    <div className='block w-full text-2xl mb-5'>
                                        <textarea className='mt-1 block w-full px-3 py-1 bg-white border border-slate-300 rounded-md text-lg placeholder-slate-400
                                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                     invalid:text-pink-600
                                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                                            type='text' placeholder='Message' name='message' required rows={3}></textarea>
                                    </div>

                                    <div className='mt-10 right-20 justify-center items-center'>
                                        <button className='bg-cyan-600 text-white font-[poppins] py-2 px-6 rounded md:ml-8 lg:ml-0
                                        hover:bg-indigo-400 duration-500' type='submit'
                                        >Send Message </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ContactUs