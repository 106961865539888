import React from 'react'
// import { GrFacebookOption, GrGoogle, GrTwitter } from 'react-icons/gr'

const DoctorTile = ({image, name, position}) => {
  return (
    <div className='border border-inherit w-11/12 md:w-64 h-1/2 p-1 pb-5 md:mr-4 mb-2'>
                    <img className='object-cover h-[380px] md:h-64 w-96' src={image} alt="doctor 1" srcset="" />
                    <div className='p-3'>
                        <h1 className='font-bold text-xl mt-8 mb-5'>{name}</h1>
                        <p className='text-lg pb-5'>{position}</p>
                        {/* <span className='flex flex-row'>
                            <i className='pr-2'><GrFacebookOption style={{ color: "#008E9B" }} size={20} /></i>
                            <i className='pr-2'><GrTwitter style={{ color: "#008E9B" }} size={20} /></i>
                            <i className='pr-2'><GrGoogle style={{ color: "#008E9B" }} size={20} /></i>
                        </span> */}
                    </div>
                </div>
  )
}

export default DoctorTile