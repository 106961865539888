import React from 'react';
import { GrFacebookOption, GrTwitter, GrGoogle, GrLinkedinOption } from 'react-icons/gr';


const Footer = () => {
    return (
        <div className='bg-cyan-700'>
            <div className=' p-6 lg:w-10/12 sm:w-full m-auto items-star'>
                <div className="flex flex-col md:flex-row  justify-center items-start m-auto border-b-2 border-inherit">
                    <div className='flex flex-col pt-8 pb-5 pl-7 mr-3 text-white md:w-5/12 sm:w-8/12'>
                        <h3 className='text-2xl font-bold'>About Us</h3>
                        <p className="mt-5 pb-5 pr-2">
                        Legacy Hospital has established itself as one of Kumasi’s
                         most acclaimed private hospitals
                        </p>
                    </div>
                    <div className='flex flex-col pt-8 pb-5 pl-7 mr-3 text-white  md:w-5/12 sm:w-8/12 '>
                        <h3 className='text-2xl font-bold'>Quick Link</h3>
                        <p className="mt-5 pb-5 pr-2">
                            <ul>
                                <li><a href='#home'>Home</a></li>
                                <li><a href='#service'>Service</a></li>
                                <li><a href='#about'>About</a></li>
                            </ul>
                        </p>
                    </div>
                    <div className='flex flex-col pt-8 pb-5 pl-7 mr-3 text-white  md:w-5/12 sm:w-8/12'>
                        <h3 className='text-2xl font-bold pb-5'>Follow us</h3>
                        <div className='flex flex-row mr-2'>
                            <i className='flex bg-blue-500 items-center hover:cursor-pointer justify-center mr-1 w-12 h-12'><GrFacebookOption style={{ color: "white" }} size={20} /></i>
                            <i className='flex bg-red-500 items-center hover:cursor-pointer justify-center mr-1 w-12 h-12'><GrGoogle style={{ color: "white" }} size={20} /></i>
                            <i className='flex bg-cyan-500 items-center hover:cursor-pointer justify-center mr-1 w-12 h-12'><GrLinkedinOption style={{ color: "white" }} size={20} /></i>
                            <i className='flex bg-blue-500 items-center hover:cursor-pointer justify-center mr-1 w-12 h-12'><GrTwitter style={{ color: "white" }} size={20} /></i>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center m-auto pt-4 text-white'>
                    <p className="text-base">© Copyright Legacy Hospital. All Rights Reserved</p>
                    {/* <p className='text-base'>Designed by <span className='text-cyan-400 underline'>Jejelly Company</span></p> */}
                </div>
            </div>
        </div>
    )
}

export default Footer