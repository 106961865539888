import React from 'react'

// import admin from '../img/amdin.jpg';

const FewWords = () => {
    return (
        <div className='bgColor2 h-full'>
            <div className='text-3xl flex font-bold text-white p-6 m-auto w-full sm:w-10/12 justify-center items-center'>
                <h2 className='uppercase'>&lt;&lt;Message From The Administrator&gt;&gt;
                </h2>
            </div>
            <div className='flex md:flex-row md:items-start sm:items-center  justify-around m-auto md:w-10/12 '>
                {/* <div className='text-3xl mr-10 font-bold text-white p-6 md:w-4/12 sm:w-10/12 justify-center items-center'>
                    <div className=''>
                        <img className='h-3/5' src={admin} alt="doctor 1" srcset="" />
                    </div>
                </div> */}
                <div className='w-full lg:w-10/12 pb-4'>
                    <p className='text-gray-400 p-6'>
                    At Legacy Hospital, we are constantly improving  our healthcare delivery system through the 
                    introduction of innovative technologies, improvement in infrastructure and the recruitment of renowned professionals. Over the past few years, the Hospital
                        has become a major referral center for specialties such as gastroenterology, plastic
                        and reconstructive Surgery, maternal health, neurological, urological,
                        and oncological issues. Be rest assured to leave your worries behind once you step
                        into our premises. We provide our clients seamless and a holistic care merging with
                        hospitality. We have friendly staff that welcomes our patients,and assures them that
                        their needs will be taken care off throughout their stay with us. Our renowned medical
                        professionals, coupled with the hospital infrastructure ensures that your well-being is
                        in trusted hands.
                        We wish you a happy stay, a healthy life, and a flourishing future.
                    </p>
                    <span className='flex text-green-500 text-right right-0 justify-end pr-6'>~ Stephen Tete Mantey</span>
                </div>

            </div>
        </div>
    )
}

export default FewWords