import React, { useState } from 'react';
import { AiOutlineMenuFold, AiOutlineClose } from 'react-icons/ai';

import logo from '../img/legacy_logo.jpeg';

const Navbar = ({ offSetValue }) => {
  const homeRef = React.useRef(null);
  const serviceRef = React.useRef(null);
  const aboutRef = React.useRef(null);
  const visionRef = React.useRef(null);
  const contactRef = React.useRef(null);

  const [open, setOpen] = useState(false);

  const toggleMenu = (value) => {
    setOpen(value);
  }

  const onToggleClick = (navRef) => {
    homeRef.current.classList.remove("active");
    serviceRef.current.classList.remove("active");
    aboutRef.current.classList.remove("active");
    visionRef.current.classList.remove("active");
    contactRef.current.classList.remove("active");
    navRef.current.classList.toggle("active");
  };


  return (
    <div className={`fixed w-full ${offSetValue > 1 ? 'bg-cyan-700' : ''} text-white  z-50 transition-all duration-500`} id='navbar'>
      <nav className="md:pl-14 p-3 md:flex justify-between">
        <div className="flex justify-between items-center">
          {/* <span className="text-3xl font-[Poppins] cursor-pointer border-2 border-red-400"> */}
          <img className="h-16 w-28 md:w-44 object-cover"
            src={logo} alt='show' />

          {/* </span> */}

          <span className="text-3xl cursor-pointer mx-2 md:hidden portrait:block">
            {open ? <AiOutlineClose onClick={() => toggleMenu(false)} size={30} /> :
              <AiOutlineMenuFold onClick={() => toggleMenu(true)} size={30} />}
          </span>
        </div>

        <ul className={`md:flex md:items-center z-[-1] md:z-auto md:static text-white absolute w-full
         left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 md:opacity-100 ${open ? 'ease-out transition-all bg-cyan-700'
            : 'opacity-0 sm:top-[-400px]'} transition-all 
         ease-in duration-500 text-white`}>
          <li className="mx-1 my-6 md:my-0">
            <a ref={homeRef} onClick={() => {
              toggleMenu(false);
              onToggleClick(homeRef)
            }} href="#home" className=" px-3 py-3 hover:bg-cyan-800 duration-500">Home</a>
          </li>
          <li className="mx-1 my-6 md:my-0">
            <a ref={serviceRef} onClick={() => {
              toggleMenu(false);
              onToggleClick(serviceRef)
            }} href="#service" className=" px-3 py-3 hover:bg-cyan-800 duration-500">Services</a>
          </li>
          <li className="mx-1 my-6 md:my-0">
            <a ref={aboutRef} onClick={() => {
              toggleMenu(false);
              onToggleClick(aboutRef)
            }} href="#about" className="active:bg-gray-900 px-3 py-3 hover:bg-cyan-800 duration-500">About</a>
          </li>
          {/* <li className="mx-1 my-6 md:my-0">
            <a ref={visionRef} onClick={() => {
              toggleMenu(false);
              onToggleClick(visionRef)
            }} href="#vision" className="active:bg-gray-900 px-3 py-3 hover:bg-cyan-800 duration-500">Vision</a>
          </li> */}
          <li className="mx-1 my-6 md:my-0">
            <a onClick={() => {
              toggleMenu(false);
            }} href="mailto:careers@legacyhospital.org" className="active:bg-gray-900 px-3 py-3 hover:bg-cyan-800 duration-500">Careers</a>
          </li>
          <li className="mx-1 my-6 md:my-0">
            <a ref={contactRef} onClick={() => {
              toggleMenu(false);
              onToggleClick(contactRef)
            }} href="#contact" className="active:bg-gray-900 px-3 py-3 hover:bg-cyan-800 duration-500">Contact</a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar