import React from 'react'

const About = () => {
    return (
        <div className='flex flex-col duration-500 transition-all portrait:w-full' id='service'>
            <div className='w-full h-24'></div>
            <div className='flex flex-col lg:flex-row justify-center items-start  w-11/12 m-auto'>
                <div className='flex flex-col basis-2/4 md:mr-12 sm:pb-12'>
                    <h1 className='text-3xl text-black font-bold uppercase pb-4'>About</h1>
                    <div className='w-12 h-1' style={{ backgroundColor: "#008E9B" }}></div>

                    <div className='flex flex-col py-2'>
                        {/* <MdMedication style={{ color: "#008E9B" }} size={40} /> */}
                        <h3 className='text-xl py-3 font-bold'>General Services</h3>
                        <p className='pb-6'>
                            Legacy  Hospital has established itself as one of Kumasi’s most acclaimed private hospitals. Since its
                            inception in 2014, we have built a coordinated patient care that ensures the provision
                            of effective treatments for all. The hospital was founded upon the initiative of <b> Prof.
                                Dr.   Joseph   Yorke,   Love   Ampomah-Yorke   and   Stephen   Tete
                                Mantey</b> whose visions were to bring simplified and a stress free high quality
                            healthcare to the people of Asokore Mampong, and Ashanti Region in general.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col basis-3/4 md:mr-12 sm:pb-12'>
                    <div>
                        <h1 className='text-3xl text-black font-bold uppercase pb-4'>Mission and Vision</h1>
                        <div className='w-12 h-1' style={{ backgroundColor: "#008E9B" }}></div>
                    </div>
                    <div className='grid lg:grid-cols-2 w-full lg:w-full sm:grid-cols-1'>
                        <div className='flex flex-col py-2 pr-2'>
                            <h3 className='text-xl py-3 font-bold'>Mission</h3>
                            <p className='pb-6'>Simplifying healthcare services delivery.
                    
                            </p>
                        </div>
                        <div className='flex flex-col py-2 pr-2'>
                            <h3 className='text-xl py-3 font-bold'>Vision</h3>
                            <p className='pb-6'>Our Vision is to bring simplified and a stress free high quality healthcare 
                            to the people of Asokore Mampong, and Ashanti Region in general</p>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default About