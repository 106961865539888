import React from 'react'
// import { GrFacebookOption, GrTwitter, GrGoogle } from 'react-icons/gr';

import doctor1 from '../img/Director - Dr. George Acquaye.jpg';
import doctor3 from '../img/Visiting Consultant - Dr Joseph Akpaloo.jpg';
import doctor2 from '../img/CEO _ Consultant Surgeon - Professor Joseph Yorke.jpg';
import doctor4 from '../img/amdin.jpg';
import doctor5 from '../img/Accountant - Foster Agyei.jpg';
import doctor6 from '../img/Cashier - Love Yorke.jpg';
import DoctorTile from './DoctorTile';

const Doctors = () => {
    return (
        <div className='flex flex-col items-start justify-cemter m-auto w-11/12'>
            <h1 className='text-3xl text-black font-bold uppercase pb-4'>Meet Our Team!</h1>
            <div className='w-12 h-1' style={{ backgroundColor: "#008E9B" }}></div>
            <div className='flex flex-row flex-wrap justify-start items-start text-black mt-6 w-full'>
                <DoctorTile image={doctor1} name="Dr. George Acquaye" position="Director" />
                <DoctorTile image={doctor2} name="Professor Joseph Yorke" position="CEO/Consultant Surgeon" />
                <DoctorTile image={doctor3} name="Dr Joseph Akpaloo" position="Visiting Consultant" />
                <DoctorTile image={doctor4} name="Stephen Tete Mantey" position="Administrator" />
                <DoctorTile image={doctor5} name="Foster Agyei" position="Accountant" />
                <DoctorTile image={doctor6} name="Love Yorke" position="Cashier" />

            </div>
        </div>
    )
}

export default Doctors