import React from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'

import Navbar from '../components/Navbar';

const Home = ({ offSetValue }) => {
    return (
        <div className='bg-fixed top-0 bg-banner bg-no-repeat bg-cover min-h-screen' id='home'>
            <div className='absolute bg-cyan-800 min-h-screen bg-opacity-80 w-full'>
                <Navbar offSetValue={offSetValue} />
                <div className='mt-56 w-10/12 m-auto'>
                    <div className='flex flex-col items-center justify-center
                     text-white md:w-8/12 m-auto sm:w-full'>
                        <img className='pb-3' src='' alt='' />
                        <h1 className='uppercase md:text-4xl text-center font-bold mb-3 sm:text-2xl'>Simplifying healthcare services delivery!!</h1>
                        <p className='flex pb-3 mt-3 text-sx lg:text-lg sm:pb-8'>
                            The vision of Legacy Hospital is to bring simplified and a stress free high quality
                            healthcare to the people of Asokore Mampong, and Ashanti Region in general.
                        </p>
                        <a href='#contact' className='focus:bg-cyan-400 mt-10 px-3 py-2 text-sm bg-cyan-500 rounded'>Make an Appointment</a>
                        <a className='mt-24 ' href='#service' ><AiOutlineArrowDown size={30} /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home